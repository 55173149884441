<template>
  <!-- eslint-disable vuejs-accessibility/form-control-has-label -->

  <!-- Loading spinner -->
  <div
    v-if="state.formVisibility === 'hidden'"
    class="container d-flex justify-content-center"
  >
    <CenteredBootstrapSpinner />
  </div>
  <!-- Payment processing spinner -->
  <ProcessingSpinner v-if="state.checkout.paymentProcessing" />
  <!-- main form shown after all data has been fetched -->
  <CartHeader
    :style="{
      visibility: state.formVisibility,
    }"
  />
  <div
    class="container justify-content-center cart-body row"
    :style="{
      visibility: state.formVisibility,
    }"
  >
    <!-- <ExitIntent v-if="!state.checkout.paymentProcessing && exitOfferRelevant" /> -->
    <PaidSubAlert v-if="checkoutData.isPaid && !ignoreUsersPaidStatus" />
    <AddedTeamAlert v-if="route.query.addedTeam" />
    <RouterCouponAlert v-if="couponIsVendorRouter || state.showVendorRouterCouponAlert" />
    <form @submit.prevent="submit" ref="form" id="form" class="row d-flex flex-wrap justify-content-around align-content-start align-items-start">
      <div class="col-md-5 col-12 order-md-2 float-left p-3">
        <UpsellCard v-if="!checkoutData.coupon.success && state.pricing.discount === 0" />
        <section class="price bg-light rounded pt-1">
          <div id="checkout-cart" class="s-cart px-4 pt-1 pb-4">
            <h4 class="d-flex justify-content-between align-items-center mb-3 mt-3 s-h4 fw-bold">
              <span>Order Summary</span>
            </h4>
            <ul class="list-group list-group-flush cart-group mb-3 border-none">
              <li class="list-group-item cart-item justify-content-between lh-condensed bg-light">
                <div class="justify-content-between d-flex flex-nowrap">
                  <div class="text-start">
                    <span id="plan-name" class="my-0 text-start">{{ state.plan.name }}</span>
                  </div>
                  <div class="text-end">
                    <span class="text-muted">{{ convertToCountryLocaleCurrency(state.pricing.plan) }}</span>
                  </div>
                </div>
                <div
                  class="w-100 text-start d-flex flex-nowrap justify-content-end align-items-middle small"
                  v-if="subscriptionProductEligibleForQuantityChange"
                >
                  <span class="my-auto">Qty:&nbsp;</span>
                  <span class="text-end qty-selector">
                    <input
                      type="number"
                      id="plan-quantity"
                      data-recurly-subscription="0"
                      data-recurly="plan_quantity"
                      v-model="checkoutData.quantity"
                      class="form-control"
                    />
                  </span>
                </div>
                <div class="d-flex justify-content-between" v-if="freeTrialActive">
                  <span class="text-muted text-start">
                    Try Speedify for {{ checkoutData.trialLength }} for free on us!
                  </span>
                </div>
              </li>
              <li
                id="dedicated-server-details"
                class="list-group-item cart-item justify-content-between lh-condensed bg-light"
                v-if="serversAvailable"
              >
                <div class="justify-content-between d-flex flex-nowrap">
                  <div class="text-start">
                    <span id="dedicated-server-name" class="my-0">{{ checkoutData.serverName }}</span>
                  </div>
                  <div>
                    <span class="text-muted">{{ convertToCountryLocaleCurrency(state.pricing.servers) }}</span>
                  </div>
                </div>
                <div class="w-100 text-start d-flex flex-nowrap justify-content-end align-items-middle small">
                  <button class="btn text-muted" type="button" id="remove-server" @click="handleRemoveServerClick">Remove</button>
                  <span class="my-auto">Qty:&nbsp;</span>
                  <span id="server-plan-quantity" class="text-end qty-selector">
                    <input
                      type="number"
                      id="server-quantity"
                      data-recurly-subscription="1"
                      data-recurly="plan_quantity"
                      v-model="checkoutData.servers"
                      class="form-control"
                    />
                  </span>
                </div>
              </li>
              <li
                id="promo-details"
                v-if="state.pricing.discount > 0 || freeTrialActive"
                class="list-group-item cart-item d-flex flex-nowrap justify-content-between bg-light text-success"
              >
                <div class="text-start">
                  <span class="my-0">Discount</span>
                  <small id="coupon-description"></small>
                </div>
                <div v-if="freeTrialActive">
                  <span class="text-success">{{ checkoutData.trialLength }} Free Trial</span>
                </div>
                <div v-else>
                  <span id="discount-amount" class="text-success">{{ convertToCountryLocaleCurrency(state.pricing.discount) }}</span>
                </div>
              </li>
              <li
                id="tax-line-item"
                class="list-group-item cart-item d-flex justify-content-between bg-light"
                v-if="state.pricing.taxes > 0"
              >
                <div>
                  <h6 class="my-0">
                    Tax
                  </h6>
                </div>
                <span id="tax-amount" class="text-muted">
                  {{ convertToCountryLocaleCurrency(state.pricing.taxes) }}
                </span>
              </li>
              <li class="list-group-item cart-item d-flex justify-content-between bg-light">
                <span id="total-descriptor" class="fw-bold">Total ({{ checkoutData.currency }})</span>
                <div>
                  <strong>{{ convertToCountryLocaleCurrency(checkoutData.totalPrice) }}</strong>
                </div>
              </li>
            </ul>
            <div class="gap-3 d-flex flex-column">
              <div class="d-flex flex-nowrap justify-content-between gap-2" v-if="state.showCouponInput || props.coupon">
                <input
                  type="text"
                  class="form-control"
                  id="redeem"
                  placeholder="Coupon"
                  name="couponInput"
                  label="Coupon"
                  @keyup.enter="applyCoupon"
                  v-model="checkoutData.coupon.inputtedCode"
                />
                <div class="input-group-append">
                  <button id="applyCouponBtn" @click="applyCoupon" type="button" class="btn btn-secondary s-redeem-btn stm-btn-coupon-redeem">Apply</button>
                </div>
              </div>
              <div 
                class="d-flex flex-nowrap justify-content-between gap-2"
                v-if="state.vat.vatNumInputVisible && state.vat.applicable"
              >
                <input
                  type="text"
                  class="form-control"
                  id="vat_number"
                  placeholder="VAT Number (optional)"
                  name="vatNumber"
                  label="VatNumber"
                  @keyup.enter="applyVatNumber"
                  v-model="state.vat.vatNumber"
                  :disabled="state.vat.exemptionApplied"
                />
                <div class="input-group-append">
                  <button 
                    id="applyVatBtn"
                    @click="applyVatNumber"
                    type="button"
                    class="btn btn-secondary s-redeem-btn stm-btn-vat-apply"
                    v-if="!state.vat.exemptionApplied"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
            <div class="alert alert-danger mt-3 mb-0 stm-msg-coupon-error" role="alert" v-if="checkoutData.coupon.invalid">
              <i class="fas fa-exclamation-circle"></i>
              &nbsp;{{ checkoutData.coupon.invalidMessage }}
            </div>
            <div class="alert alert-success mt-3 mb-0 stm-msg-coupon-success" role="alert" v-if="checkoutData.coupon.success">
              <i class="fas fa-check-circle"></i>
              &nbsp;Coupon has been applied!
            </div>
            <div class="alert alert-success mt-3 mb-0 stm-msg-coupon-success" role="alert" v-if="state.vat.exemptionApplied">
              <i class="fas fa-check-circle"></i>
              &nbsp;VAT exemption has been applied!
            </div>
            <div class="alert alert-danger mt-3 mb-0 stm-msg-coupon-error" role="alert" v-if="state.vat.invalidNumber && state.vat.applicable">
              <i class="fas fa-exclamation-circle"></i>
              &nbsp;Invalid VAT number
            </div>
            <div class="hstack gap-2 d-flex justify-content-center py-2">
              <button v-if="!state.showCouponInput && !props.coupon" @click="showCouponInput" type="button" class="btn btn-link">Have a coupon code?</button>
              <div v-if="!state.showCouponInput && !state.vat.vatNumInputVisible && state.vat.applicable" class="vr"></div>
              <button
                @click="state.vat.vatNumInputVisible = true" 
                type="button" 
                class="btn btn-link"
                v-if="state.vat.applicable && !state.vat.vatNumInputVisible"
              >
                VAT exempt?
              </button>
            </div>
          </div>
        </section>
      </div>
      <div class="col-md-7 col-12 order-md-1 float-left flex-row justify-content-left text-start mb-5">
        <section>
          <AccountSelection 
            :displayFormError="state.displayFormError"
            :invalidEmail="v$.checkoutData.email.$invalid"
            :invalidEmailRepeat="v$.checkoutData.emailRepeat.$invalid"
          />
          <hr class="mb-4" />
          <h4 id="free-trial-header" class="mb-3 s-h4 fw-bold" v-if="state.checkout.hideBillingForm">2: Complete Captcha</h4>
          <h4 id="payment-header" class="mb-3 s-h4 fw-bold" v-else>2: Select Payment</h4>
          <div v-if="subscriptionProductRequiresCoupon && !state.checkout.hideBillingForm && state.formVisibility === 'visible'">
            <div class="alert alert-danger">
              This product can only be purchased or redeemed with a valid coupon.
            </div>
          </div>
          <div v-else-if="state.checkout.hideBillingForm" class="border shadow p-4">
            <vueRecaptcha
              class="stm-recaptcha-failed"
              :sitekey="recaptchaSiteKey"
              @verify="recaptchaVerified"
              @expire="recaptchaExpired"
              ref="recaptcha"
            />
            <div class="w-100 d-grid gap-2 mt-3" @click="showVuelidateErrors" @keydown="showVuelidateErrors">
              <div class="alert alert-danger mt-3 stm-msg-checkout-error" role="alert" v-if="state.braintree.error">
                <span>{{ state.braintree.error }}</span>
              </div>
              <div class="alert alert-danger mt-3 stm-msg-checkout-error" role="alert" v-if="state.checkout.failed">
                <i class="fas fa-exclamation-circle"></i>
                &nbsp;Error: Unable to process order
                <hr />
                <span v-html="state.checkout.failureError"></span>
              </div>
              <button
                type="submit"
                class="btn btn-primary btn-lg stm-btn-order-submit"
                @click="subscribe"
                :disabled="v$.$validationGroups.freeCardlessTrial.$invalid"
              >
                Start My Free Trial
              </button>
            </div>
          </div>
          <div class="accordion payment-accordion" id="paymentAccordion" v-else>
            <div 
              class="accordion-item col-12"
              v-if="enableApplePay && checkoutPricing"
            >
              <ApplePayAccordionPanel 
                :recurlyPricing="checkoutPricing"
                :plan="state.plan.name"
                :disableButton="v$.$validationGroups.applePay.$invalid"
                @hideApplePay="disableApplePay"
                @showApplePayVuelidate="showVuelidateErrors"
                @paymentProcessing="showProcessingSpinner"
                @paymentFailed="hideProcessingSpinner"
              />
            </div>
            <div class="accordion-item col-12">
              <h4 class="accordion-header" id="headingOne">
                <button
                  type="button"
                  class="accordion-button collapsed d-flex flex-row justify-content-between gap-2 stm-btn-accordion-credit-card"
                  data-bs-toggle="collapse"
                  data-bs-target="#accordion-2"
                  aria-expanded="false"
                  aria-controls="accordion-2"
                >
                  <div class="d-flex justify-content-between w-100">
                    <div>Credit or Debit</div>
                    <div>
                      <img alt="Visa supported" class="icon" :src="ccVisaSvg" />
                      <img alt="Mastercard supported" class="icon" :src="ccMastercardSvg" />
                      <img alt="Amex supported" class="icon" :src="ccAmexSvg" />
                      <img alt="Discover supported" class="icon" :src="ccDiscoverSvg" />
                    </div>
                  </div>
                </button>
              </h4>
              <div id="accordion-2" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#paymentAccordion">
                <div class="accordion-body card-element row" style="overflow: overlay">
                  <div class="col-12 col-md-6 my-2">
                    <label for="first_name" class="small">First Name</label>
                    <input
                      type="input"
                      data-recurly="first_name"
                      id="first_name"
                      name="first-name"
                      v-model="checkoutData.firstName"
                      class="form-control"
                    />
                    <small style="color: red" v-if="v$.checkoutData.firstName.$invalid && state.displayFormError">
                      First name cannot be blank.
                    </small>
                  </div>
                  <div class="col-12 col-md-6 my-2">
                    <label for="last_name" class="small">Last Name</label>
                    <input
                      type="input"
                      data-recurly="last_name"
                      id="last_name"
                      name="last-name"
                      v-model="checkoutData.lastName"
                      class="form-control"
                    />
                    <small style="color: red" v-if="v$.checkoutData.lastName.$invalid && state.displayFormError">
                      Last name cannot be blank.
                    </small>
                  </div>
                  <div class="col-12 col-md-6 my-2">
                    <label for="postal_code" class="small">Postal Code</label>
                    <input
                      type="text"
                      data-recurly="postal_code"
                      id="postal_code"
                      name="postal-code"
                      v-model="checkoutData.postalCode"
                      class="form-control"
                      :maxlength="state.postalCodeLimit"
                      title="Postal Code (contains alphabets, numerals, spaces, hyphens)"
                    />
                    <small style="color: red" v-if="v$.checkoutData.postalCode.$invalid && state.displayFormError">
                      Postal code must be valid.
                    </small>
                  </div>
                  <CountrySelect :formError="v$.checkoutData.country.$invalid && state.displayFormError" />
                  <div
                    id="recurly-elements"
                    v-if="!state.checkout.hideBillingForm"
                    class="my-2 recurly-input"
                    :class="{
                      'mb-3': !(v$.state.checkout.validCard.$invalid && checkoutData.displayFormError),
                    }"
                  >
                    <label for="recurly-elements" class="small">Card</label>
                  </div>
                  <small style="color: red" class="mb-3" v-if="v$.state.checkout.validCard.$invalid && checkoutData.displayFormError">
                    Please provide required credit or debit card information.
                  </small>
                  <vueRecaptcha
                    class="stm-recaptcha-failed"
                    :sitekey="recaptchaSiteKey"
                    @verify="recaptchaVerified"
                    @expire="recaptchaExpired"
                    ref="recaptcha" 
                  />
                  <div class="w-100 d-grid gap-2 mt-3" @click="showVuelidateErrors" @keydown="showVuelidateErrors">
                    <div class="alert alert-danger mt-3 stm-msg-checkout-error" role="alert" v-if="state.braintree.error">
                      <span>{{ state.braintree.error }}</span>
                    </div>
                    <div class="alert alert-danger mt-3 stm-msg-checkout-error" role="alert" v-if="state.checkout.failed">
                      <i class="fas fa-exclamation-circle"></i>
                      &nbsp;Error: Unable to process purchase
                      <hr />
                      <span v-html="state.checkout.failureError"></span>
                    </div>
                    <button
                      type="submit"
                      class="btn btn-primary btn-lg stm-btn-order-submit"
                      @click="subscribe"
                      :disabled="v$.$validationGroups.creditCard.$invalid"
                      v-if="!state.checkout.hideBillingForm"
                    >
                      Complete Order
                    </button>
                  </div>
                  <div class="row d-flex flex-nowrap justify-content-around w-100 text-center mt-3">
                    <div class="small col">
                      <img alt="secure" class="icon" :src="lockSvg" />
                      Encrypted and secure payments
                    </div>
                    <div class="small col align-top">
                      <img alt="money back" class="icon" :src="clockRotateLeftSvg" />
                      30-Day Money Back Guarantee
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item col-12">
              <PaypalAccordionPanel
                :disableButton="v$.$validationGroups.paypal.$invalid"
                @paymentProcessing="showProcessingSpinner"
                @paymentFailed="hideProcessingSpinner"
                @showVuelidate="showVuelidateErrors"
              />
            </div>
            <div class="accordion-item col-12" v-if="state.showVenmo">
              <VenmoAccordionPanel 
                :disableButton="v$.$validationGroups.venmo.$invalid"
                @paymentProcessing="showProcessingSpinner"
                @paymentFailed="hideProcessingSpinner"
                @showVuelidate="showVuelidateErrors"
              />
            </div>
            <div class="accordion-item col 12" @click="showVuelidateErrors" v-if="enableGooglePay">
              <GooglePayAccordionPanel
                :disableButton="v$.$validationGroups.googlePay.$invalid"
                :formRef="form"
                @paymentProcessing="showProcessingSpinner"
                @paymentFailed="hideProcessingSpinner"
                @showVuelidate="showVuelidateErrors"
              />
            </div>
          </div>
        </section>
      </div>
      <input type="hidden" data-recurly="token" name="recurly-token" />
      <input type="hidden" data-recurly="coupon" name="coupon" v-model="checkoutData.coupon.inputtedCode" />
      <input type="hidden" id="recurly-plan-speedify" data-recurly-subscription="0" data-recurly="plan" name="plan" v-model="checkoutData.plan" />
      <input
        type="hidden"
        id="recurly-plan-servers"
        ref="recurlyPlanServers"
        data-recurly-subscription="1"
        data-recurly="plan"
        name="plan"
        v-model="checkoutData.serverCode"
        v-if="serversAvailable"
      />
      <input type="hidden" data-recurly="vat_number" name="vat-number" v-model="state.vat.vatNumber" />
    </form>
  </div>
</template>

<script setup>
import { computed, reactive, ref, onMounted, watch, useTemplateRef } from 'vue';
import * as Sentry from '@sentry/vue';
import { storeToRefs } from 'pinia';
import { useRouter, useRoute } from 'vue-router';
import { useVuelidate } from '@vuelidate/core';
import vueRecaptcha from 'vue3-recaptcha2';
import { required, email, maxLength, requiredIf, sameAs } from '@vuelidate/validators';
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';

import countryCurrencyMap from '@/services/countryCurrencyMap';
import { createPurchase, getCoupon, checkPlan } from '@/services/backend';
import { numberToCountryLocale } from '@/services/currencyTranslation';
import CountrySelect from '@/components/CountrySelect.vue';
import PaypalAccordionPanel from '@/components/PaypalAccordionPanel.vue';
import VenmoAccordionPanel from '@/components/VenmoAccordionPanel.vue';
import ApplePayAccordionPanel from '@/components/ApplePayAccordionPanel.vue';
import GooglePayAccordionPanel from '@/components/GooglePayAccordionPanel.vue';
import UpsellCard from '@/components/UpsellCard.vue';
import { useAnalyticsStore } from '@/stores/analytics';
import ExitIntent from '@/components/ExitIntent.vue';
import CenteredBootstrapSpinner from '../components/ux/CenteredBootstrapSpinner.vue';
import ProcessingSpinner from '@/components/ux/ProcessingSpinner.vue';
import CartHeader from '@/components/CartHeader.vue';
import AccountSelection from '@/components/AccountSelection.vue';
import PaidSubAlert from '@/components/PaidSubAlert.vue';
import AddedTeamAlert from '@/components/AddedTeamAlert.vue';
import RouterCouponAlert from '@/components/RouterCouponAlert.vue';
import { useGeoIPStore } from '@/stores/geoip';
import { useCheckoutStore } from '@/stores/checkout';
import { orderCompleteRedirect } from '@/services/orderCompleteURL';
import { updateUrlQuery } from '@/services/windowAPI';

import ccVisaSvg from '@/assets/svg/cc-visa.svg';
import ccAmexSvg from '@/assets/svg/cc-amex.svg';
import ccMastercardSvg from '@/assets/svg/cc-mastercard.svg';
import ccDiscoverSvg from '@/assets/svg/cc-discover.svg';
import lockSvg from '@/assets/svg/lock.svg';
import clockRotateLeftSvg from '@/assets/svg/clock-rotate-left.svg';

const router = useRouter();
const analyticsStore = useAnalyticsStore();
const geoIPStore = useGeoIPStore();
const checkoutStore = useCheckoutStore();
const {
  checkoutData,
  ignoreUsersPaidStatus,
  reloadCartWithoutServers,
} = checkoutStore;

const { 
  freeTrialActive,
  couponIsVendorRouter,
  subscriptionProductRequiresCoupon,
  subscriptionProductEligibleForQuantityChange,
  serversAvailable,
  exitOfferRelevant,
} = storeToRefs(checkoutStore);

const form = useTemplateRef('form');
const recurlyPlanServers = ref(null);
const recaptcha = ref();

const route = useRoute();
const recaptchaSiteKey = import.meta.env.VITE_RECAPTCHA_KEY;
const enableIndividualServers = import.meta.env.VITE_ENABLE_INDIVIDUAL_SERVERS === 'true';
const enableVenmo = import.meta.env.VITE_ENABLE_VENMO === 'true';
const routerCouponPrefixes = import.meta.env.VITE_ROUTER_COUPON_PREFIXES;
// we'll reassign if applePay is enabled but browser doesn't support
let enableApplePay = import.meta.env.VITE_ENABLE_APPLE_PAY === 'true';
const enableGooglePay = import.meta.env.VITE_ENABLE_GOOGLE_PAY === 'true';
let captchaResponse = null;

/* eslint-disable vue/require-default-prop, vue/no-setup-props-destructure */
const props = defineProps({
  plan: String,
  coupon: String,
  quantity: Number,
  servers: Number,
  inapp: Number,
  couponFocus: Boolean,
});

const state = reactive({
  formVisibility: 'hidden',
  plan: {
    name: 'Speedify Unlimited Monthly Subscription',
    interval: '',
  },
  checkout: {
    failed: false,
    failureError: null,
    success: false,
    hideBillingForm: false,
    validCard: false,
    disableSubscribeBtn: true,
    paymentProcessing: false,
    captchaDone: false,
  },
  pricing: {
    plan: 0,
    planNext: 0,
    servers: 0,
    taxes: 0,
    discount: 0,
    total: 0,
  },
  vat: {
    applicable: false,
    vatNumInputVisible: false,
    exemptionApplied: false,
    invalidNumber: false,
    vatNumber: null,
  },
  braintree: {
    error: '',
  },
  displayFormError: false,
  postalCodeLimit: 10,
  showCouponInput: props.couponFocus || false,
  showVenmo: enableVenmo,
  showVendorRouterCouponAlert: false,
});

const convertToCountryLocaleCurrency = (n) => numberToCountryLocale(n, checkoutData.country, checkoutData.currency);

// venmo only supports USD
watch(
  () => checkoutData.currency, 
  newCurrency => {
    state.showVenmo = newCurrency === 'USD' && enableVenmo;
  }
);

const postalCodeRequired = () => postcodeValidatorExistsForCountry(checkoutData.country);
const validPostalCode = value => {
  if (!postcodeValidatorExistsForCountry(checkoutData.country)) {
    return true;
  }
  return postcodeValidator(value, checkoutData.country);
};

const rules = computed(() => ({
  checkoutData: {
    firstName: { required },
    lastName: { required },
    postalCode: {
      required: requiredIf(postalCodeRequired),
      maxLength: maxLength(state.postalCodeLimit),
      validPostalCode,
    },
    country: { required },
    email: {
      required,
      email,
    },
    emailRepeat: {
      sameAsEmail: sameAs(computed(() => checkoutData.email)),
    },
  },
  state: {
    checkout: {
      captchaDone: {
        required,
        checked: sameAs(true),
      },
      validCard: {
        required,
        valid: sameAs(true),
      },
    },
  },
  $validationGroups: {
    creditCard: [
      'checkoutData.firstName',
      'checkoutData.lastName',
      'checkoutData.postalCode',
      'checkoutData.country',
      'checkoutData.email',
      'checkoutData.emailRepeat',
      'state.checkout.captchaDone',
      'state.checkout.validCard',
    ],
    paypal: [
      'checkoutData.email',
      'checkoutData.emailRepeat',
    ],
    venmo: [
      'checkoutData.email',
      'checkoutData.emailRepeat',
    ],
    applePay: [
      'checkoutData.email',
      'checkoutData.emailRepeat',
    ],
    googlePay: [
      'checkoutData.email',
      'checkoutData.emailRepeat',
    ],
    freeCardlessTrial: [
      'checkoutData.email',
      'checkoutData.emailRepeat',
      'state.checkout.captchaDone',
    ],
  },
}));

const v$ = useVuelidate(rules, { checkoutData, state });

// vuelidate shouldn't complain until the user tries to submit
const showVuelidateErrors = () => {
  state.displayFormError = true;
};

const showProcessingSpinner = () => {
  state.checkout.paymentProcessing = true;
};

const hideProcessingSpinner = () => {
  state.checkout.paymentProcessing = false;
};

let checkoutPricing;
let elements;

const recaptchaVerified = token => {
  captchaResponse = token;
  state.checkout.captchaDone = true;
};

const recaptchaExpired = () => {
  captchaResponse = null;
  state.checkout.captchaDone = false;
};

watch(() => geoIPStore.details.country, () => {
  checkoutData.country = geoIPStore.details.country;
  if (checkoutData.country === 'US') {
    state.postalCodeLimit = 10;
  } else {
    state.postalCodeLimit = 15;
  }
});

const disableApplePay = () => {
  enableApplePay = false;
};

const showCouponInput = () => {
  state.showCouponInput = true;
};

const applyCoupon = () => {
  // TODO: this is weird but let's check for vendor router coupons here as well
  // coupon codes don't make it to checkoutStore if they aren't validated by recurlyJS
  state.showVendorRouterCouponAlert = routerCouponPrefixes.split(',').some((prefix) => checkoutData.coupon.inputtedCode.startsWith(prefix));
  if (state.showVendorRouterCouponAlert) {
    checkoutData.coupon.routerCode = checkoutData.coupon.inputtedCode;
    return;
  }
  checkoutData.coupon.routerCode = '';
  checkoutPricing?.coupon(checkoutData.coupon.inputtedCode.toLowerCase()).done();
};

const applyVatNumber = async () => {
  await checkoutPricing?.tax({ 'vat_number': state.vat.vatNumber }).done((price) => {
    if (price.taxes[0].rate !== '0.0') {
      state.vat.invalidNumber = true;
      state.vat.exemptionApplied = false;
    } else {
      state.vat.invalidNumber = false;
      state.vat.exemptionApplied = true;
      // let's be sure that the number we send to PI is valid, rather than trusting v-model
      checkoutData.vatNumber = state.vat.vatNumber;
    }
  });
};

const handleRemoveServerClick = () => {
  reloadCartWithoutServers();
};

onMounted(async () => {
  try {
    if (props.couponFocus) {
      const couponFormElement = document.getElementById('redeem');
      // form is hidden at this time, so focusing right away does not work
      setTimeout(() => couponFormElement.focus(), 900);
    }

    /* eslint-disable no-undef */
    const clientInstance = await braintree.client.create({ authorization: import.meta.env.VITE_BRAINTREE_KEY }); 
    const dataCollectorInstance = await braintree.dataCollector.create({
       client: clientInstance,
       kount: true,
    });
    const { deviceData } = dataCollectorInstance;
    recurly.configure({
      publicKey: import.meta.env.VITE_RECURLY_KEY,
      fraud: {
        braintree: {
          deviceData,
        },
      },
      currency: geoIPStore.details.currency,
    });
     
  } catch (err) {
    state.braintree.error = err;
    recurly.configure(import.meta.env.VITE_RECURLY_KEY)
  }

  checkoutPricing = recurly.Pricing.Checkout();
  elements = recurly.Elements();
  /* eslint-enable no-undef */
  const cardElement = elements.CardElement({
    inputType: 'mobileSelect',
    style: {
      fontSize: '1em',
      placeholder: {
        color: 'gray !important',
        fontWeight: 'bold',
        content: {
          number: 'Card number',
          cvv: 'CVC',
        },
      },
      invalid: {
        fontColor: 'red',
      },
    },
  });

  cardElement.attach('#recurly-elements');

  cardElement.on('change', cardObj => {
    state.checkout.validCard = cardObj.valid;
  });

  checkoutPricing.on('set.address', addressObj => {
    const currencyCode = countryCurrencyMap(addressObj.country);
    checkoutPricing.currency(currencyCode).done();
  });

  checkoutPricing.on('change', pricingState => {
    state.plan.price = Number(pricingState.now?.items[0]?.amount) || 0;
    state.plan.name = checkoutPricing.items?.subscriptions[0]?.subscription?.items?.plan?.name;
    state.pricing.planNext = Number(pricingState.next?.items[0]?.amount) || 0;
    
    state.pricing.servers = Number(pricingState.now?.items[1]?.plan) || 0;
    state.pricing.taxes = Number(pricingState.now?.taxes) || 0;
    state.vat.applicable = Boolean(pricingState.taxes[0]?.type === 'vat');
    state.pricing.discount = Number(pricingState.now?.discount) || 0;
    state.pricing.plan = Number(pricingState.now?.items[0]?.plan) || 0;
    checkoutData.totalPrice = Number(pricingState.now?.total) || 0;
    // shouldn't give the illusion that cardless products are free to claim
    // so, display a price if this is a cardless plan without the relevant coupon
    // pricingState.now still reads 0, so use .next in this case
    if (subscriptionProductRequiresCoupon.value && !state.checkout.hideBillingForm) {
      state.pricing.plan = Number(pricingState.next?.items[0]?.plan);
      checkoutData.totalPrice = Number(pricingState.next.total);
      checkoutData.planTrial = false;
      // only almost redundant. if url has a valid coupon for a cardless plan on page load,
      // the product price may be 0 erroneously due to a race condition in recurlyJS
      // this happens if code in set.coupon completes first, and hideBillingForm is true here
    } else if (subscriptionProductRequiresCoupon.value && state.checkout.hideBillingForm) {
      state.pricing.plan = Number(pricingState.next?.items[0]?.plan);
      checkoutData.totalPrice = Number(pricingState.now?.total) || 0;
    } else if (freeTrialActive) { // persist the real cost of the plan item while totaling 0
      state.pricing.plan = Number(pricingState.next?.items[0]?.plan);
    }
    // we need to pay VAT in EUR
    if (state.vat.applicable) {
      checkoutPricing.currency('EUR').done()
      checkoutData.currency = 'EUR';
    }
    if (!state.vat.applicable) {
      state.vat.exemptionApplied = false;
    }
  });
  
  checkoutPricing.on('set.plan', planObj => {
    state.plan.interval = planObj.plan.period.length === 12 ? 'yearly' : 'monthly';
    if (planObj.plan.trial) {
      checkoutData.planTrial = true;
      checkoutData.trialLength = `${planObj.plan.trial.length} ${planObj.plan.trial.interval}`;
    }
    if (planObj.subscription.id === '1') {
      checkoutData.serverName = planObj.plan.name;
    }
    state.formVisibility = 'visible';
  });

  checkoutPricing.on('set.coupon', coupon => {
    checkoutData.coupon.success = true;
    checkoutData.coupon.invalid = false;
    checkoutData.coupon.validatedCode = checkoutData.coupon.inputtedCode;
    // workaround: https://github.com/Connectify/speedify-cart/pull/340#discussion_r1525411310
    updateUrlQuery('coupon', checkoutData.coupon.validatedCode);
    if (coupon.discount.type === 'free_trial') {
      const pluralChar = coupon.discount.trial.amount === 1 ? '' : 's';
      checkoutData.trialLength = `${coupon.discount.trial.amount} ${coupon.discount.trial.unit}${pluralChar}`;
      checkoutData.couponTrial = true;
    } else {
      checkoutData.couponTrial = false;
    }
    // guard cardless products from being cardless without requisite coupon
    if (checkoutData.plan.includes('cardless')) {
      if (coupon.applies_to_plans
        && !coupon.applies_to_all_plans
        && coupon.discount.type === 'free_trial'
      ) {
        state.checkout.hideBillingForm = true;
        checkoutData.totalPrice = 0;
      } else {
        state.checkout.hideBillingForm = false;
      }
    }
  });

  checkoutPricing.on('error.coupon', async () => {
    const couponInfo = await getCoupon(checkoutData.coupon.inputtedCode);
    const couponPlan = couponInfo?.plans?.[0]?.code;
    if (couponInfo.error || couponInfo.state !== 'redeemable') {
      if (couponInfo.state === 'maxed_out') {
        checkoutData.coupon.invalidMessage = 'Coupon has no more uses left';
      } else if (couponInfo.state === 'expired'){
        checkoutData.coupon.invalidMessage = 'Coupon has expired';
      } else {
        checkoutData.coupon.invalidMessage = 'Invalid coupon code';
      }
      checkoutData.coupon.invalid = true;
      checkoutData.coupon.success = false;
      // TODO: look at consolidating all the different places we handle this
      if (couponIsVendorRouter) {
        checkoutData.coupon.routerCode = checkoutData.coupon.inputtedCode;
      }
      // checkoutPricing holds on to latest valid coupon, so
      // reset input field to whatever coupon is currently applied
      if (checkoutData.coupon) {
        checkoutData.coupon.inputtedCode = checkoutData.coupon.validatedCode;
      }
      return;
    }
    if (couponPlan && checkoutData.coupon.inputtedCode !== couponPlan) {
      checkoutData.coupon.invalid = false;
      checkoutData.coupon.success = true;
      router.replace({
        query: {
          plan: couponPlan, 
          coupon: checkoutData.coupon.inputtedCode, 
        },
      });
    }
  });
  checkoutPricing.attach(form.value);
});

const subscribe = async () => {
  state.checkout.failed = false;
  state.checkout.failureError = null;
  state.checkout.disableSubscribeBtn = true;
  state.checkout.paymentProcessing = true;
  if (state.checkout.hideBillingForm) {
    const result = await createPurchase(checkoutData, captchaResponse, undefined, analyticsStore.details, 'credit_card');
    if (result.status === 'success') {
      state.checkout.success = true;
      orderCompleteRedirect(checkoutData.loggedIn);
    } else {
      state.checkout.failed = true;
      state.checkout.failureError = result.error.message;
      state.checkout.disableSubscribeBtn = false;
      state.checkout.paymentProcessing = false;
      recaptcha.value.reset();
      state.checkout.captchaDone = false;
    }
  }
  // eslint-disable-next-line no-undef
  recurly.token(elements, form.value, async (err, token) => {
    if (err) {
      Sentry.captureException(err.message);
      state.checkout.failureError = err.message;
      state.checkout.failed = true;
    } else {
      const result = await createPurchase(checkoutData, captchaResponse, token.id, analyticsStore.details, 'credit_card');
      if (result.status === 'success') {
        state.checkout.success = true;
        orderCompleteRedirect(checkoutData.loggedIn);
      } else if (result.error?.errorCode === 'ERROR_MISSING_SPEEDIFY_LICENSE'){
        checkoutData.isPaid = false;
      } else {
        state.checkout.failed = true;
        state.checkout.failureError = result.error.message;
        state.checkout.disableSubscribeBtn = false;
        state.checkout.paymentProcessing = false;
        recaptcha.value.reset();
        state.checkout.captchaDone = false;
      }
    }
  });
};
</script>
<style scoped>
#redeem:focus {
  border: 2px solid #3fb4fc;
  -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
          border-radius: 5px;

  -webkit-box-shadow: 0px 0px 6px #3fb4fc;
     -moz-box-shadow: 0px 0px 6px #3fb4fc;
          box-shadow: 0px 0px 6px #3fb4fc;
}
</style>
